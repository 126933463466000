body {
  padding: 0;
  margin: 0;
}

.device-div-marker {
  width: 18px;
  height: 18px;
  margin-left: -10px;
  margin-top: -10px;
  background: #1275e8;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  border-radius: 100%;
  animation: out 3s infinite ease-out;
}

@keyframes out {
  50% {
    box-shadow: 0px 0px 0px 0px rgba(68, 168, 235, 0.6);
  }
  100% {
    box-shadow: 0px 0px 0px 30px rgba(255, 255, 255, 0);
  }
}
